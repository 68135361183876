/*--- FeaturedRepos Styles ---*/

.recent__activities {
  margin-top: 0.5rem !important;
  align-items: stretch;
}

.recent__activities-grid-container {
  padding: 16px;
}

.recent__activities-accordion-title img {
  margin-right: 0.5rem !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.recent__activities-full-w {
  width: 100%;
}

.recent__activities-card-grid {
  display: flex;
  justify-content: center;
}

/*--- Media Queries ---*/

@media (max-width: 960px) {
  .recent__activities-full-w {
    justify-content: center;
    margin: 0 auto 1rem auto !important;
    max-width: 90% !important;
  }
}

@media (max-width: 420px) {
  .recent__activities-grid-container {
    padding: 0 !important;
  }
}
