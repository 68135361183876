/*--- InfoCard styles ---*/

.info__card {
  display: flex;
  padding: 5px;
  margin-bottom: 1rem;
}

.info__card__details {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.info__card__content {
  flex: 1 0 auto;
}

.info__card__title {
  font-weight: 500 !important;
}

.info__card-description-bullets {
  margin-left: 16px;
}

.info__card-description-bullets .MuiTypography-body1 {
  font-size: 0.9rem !important;
}

.info__card__media {
  width: 100px;
  height: 100px;
  background-size: contain !important;
}

/*--- Media Queries --*/

@media (max-width: 600px) {
  .info__card__media {
    display: none !important;
  }
}
