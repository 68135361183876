/*--- Education and Work styles ---*/
.education__and__work-container-grid {
  margin-top: 1rem !important;
}

.education__and__work-full-w {
  width: 100% !important;
}

/*--- Media Queries ---*/

@media (max-width: 960px) {
  .education__and__work-full-w {
    justify-content: center;
    margin: 0 auto 1rem auto !important;
    max-width: 90% !important;
  }
}
