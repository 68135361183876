/*--- RepoCard styles ---*/

.repo-card {
  max-width: 330px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.repo-card-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.repo-card-content-grid {
  display: flex;
  flex-direction: column;
  padding-bottom: 0.8rem;
}

.repo-card-details-grid {
  flex: 1 !important;
  display: flex;
  flex-direction: column;
}

.repo-card-figure {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  box-sizing: border-box;
  padding: 0.5rem 1rem 0 1rem;
  margin-bottom: 1rem;
}

.repo-card-figure img {
  max-width: 100%;
  max-height: 100%;
}

.repo-card-details-main {
  flex: 1 !important;
}

.repo-card-meta-grid {
  flex-basis: 0 !important;
  margin-top: 2rem !important;
}

.repo-card-actions {
  justify-content: space-around;
}
