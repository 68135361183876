/*--- Profile styles ---*/

.profile__main-container-grid {
  margin-top: 1rem !important;
}

.profile__personal-card-grid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.profile__about-card-grid {
  justify-content: flex-end;
  align-items: center;
}

/*--- Media Queries ---*/

@media (max-width: 960px) {
  .profile__personal-card-grid {
    justify-content: center;
    margin: 0 auto 1rem auto !important;
    max-width: 90% !important;
  }
  .profile__about-card-grid {
    max-width: 90% !important;
    margin: 0 auto !important;
  }
}
