/*--- GithubVCard stylings ---*/

.github__vcard-list .MuiListItem-gutters {
  padding: 0 !important;
}

.MuiListItemIcon-root {
  min-width: 30px !important;
}

.MuiListItemIcon-root img {
  height: 1.2rem !important;
  width: 1.2rem !important;
}

.github__vcard {
  font-size: 1.2rem;
}

.github__vcard-list .MuiListItem-button:hover {
  background-color: transparent !important;
}
