/*--- About section styles ---*/

.about__container {
  align-self: stretch;
  display: flex;
  height: 100%;
  padding: 16px;
  flex-direction: column;
}

.about__container-heading {
  margin-bottom: 2rem !important;
}

.about__container-about-para {
  margin-bottom: 1.5rem !important;
}

.about__container-about-para:last-child {
  margin-bottom: 0 !important;
}
